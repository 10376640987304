import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Link,
  useLocation,
  useRouteMatch,
  useNavigate,
} from "react-router-dom";
import UserCard from "./UserCard";
import SectorSignIn from "./Sectors";
import { media } from "../../utils/media";
import { IMAGES } from "../../constants/constants";
import HambergurMenu from "../svg/HambergurMenu";
import ArrowIcon from "../svg/ArrowIcon";
import MobileSideBar from "./MobileSideBar";

const Header = () => {
  const location = useLocation();
  const [showMenuDetail, setShowMenuDetail] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const userProfileRef = useRef(null);
  const userCardRef = useRef(null);

  const isLoggedIn = window.localStorage.getItem("loginStatus") === "true";
  const navigate = useNavigate();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, []);

  let today = new Date();
  let hour = today.getHours();
  const handleSignOut = () => {
    window.localStorage.setItem("loginStatus", "false");
    window.localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userProfileRef.current &&
        !userProfileRef.current.contains(event.target) &&
        userCardRef.current &&
        !userCardRef.current.contains(event.target)
      ) {
        setShowUserProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserProfile]);

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <HeaderWrap>
        <NavBarStart>
          <MobileMenu
            isOpen={showMenuDetail}
            onIconClick={() => setShowMenuDetail(!showMenuDetail)}
          ></MobileMenu>
          <LogoDesktop
            src={IMAGES.logo_big}
            alt="Benchmarks"
            onClick={() => {
              navigate(isLoggedIn ? "/internet-landscape" : "/");
            }}
            
          />
          {/* <LogoMobile
            src={IMAGES.logo_small}
            alt="Benchmarks"
            onClick={() => {
              navigate(isLoggedIn ? "/internet-landscape" : "/");
            }}
          /> */}
        </NavBarStart>
        <NavBarMid>
          <NavLink
            isactive={activeLink === (isLoggedIn ? "/internet-landscape" : "/")}
            onClick={() => navigate(isLoggedIn ? "/internet-landscape" : "/")}
          >
            Home
          </NavLink>

          {isLoggedIn ? (
            <SectorSignIn />
          ) : (
            <NavLink
              isactive={activeLink === "/industries"}
              onClick={() => navigate("/industries")}
            >
              Sectors
            </NavLink>
          )}
          <NavLink
            isactive={
              activeLink ===
              (isLoggedIn ? "/company-listing-new" : "/companies")
            }
            onClick={() =>
              navigate(isLoggedIn ? "/company-listing-new" : "/companies")
            }
          >
            Companies
          </NavLink>
          <NavLink
            isactive={activeLink === "/brands"}
            onClick={() => navigate("/brands")}
          >
            Brands
          </NavLink>
        </NavBarMid>
        <NavBarEnd>
          {isLoggedIn ? (
            <ProfileWrapper>
              <Profile
                onClick={() => {
                  setShowUserProfile(!showUserProfile);
                }}
                ref={userProfileRef}
              >
                <GreetingMessageDesktop>
                  {hour < 17
                    ? hour < 12
                      ? "Good Morning "
                      : "Good Afternoon "
                    : "Good Evening "}
                  {window.localStorage.getItem("user_name") !== undefined
                    ? window.localStorage.getItem("user_name")
                    : window.localStorage.getItem("email").split("@")[0]}
                </GreetingMessageDesktop>
                <GreetingMessageMobile>
                  &nbsp;
                  {window.localStorage.getItem("user_name") !== undefined
                    ? window.localStorage.getItem("user_name")
                    : window.localStorage.getItem("email").split("@")[0]}
                </GreetingMessageMobile>
                <IconArrow
                  color="#A0A6A9"
                  rotate={showUserProfile ? 270 : 90}
                />
              </Profile>
              {showUserProfile && (
                <div
                  ref={userCardRef}
                  style={{
                    position: "relative",
                    display: "flex",
                    backgroundColor: "pink",
                  }}
                >
                  <UserCard onCloseProfile={() => setShowUserProfile(false)} />
                </div>
              )}
            </ProfileWrapper>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "/signin3")}
            >
              Sign in
            </div>
          )}
        </NavBarEnd>
        {showMenuDetail ? (
          <MobileSideBar
            isOpen={showMenuDetail}
            onCloseClick={() => {
              setShowMenuDetail(false);
            }}
          />
        ) : null}
      </HeaderWrap>
    </div>
  );
};

export default Header;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  border-bottom: 1px solid #c9c9c9;
  height: 7vh;
`;
const Dropbutton = styled.button`
  border-radius: 50%;
  border: 0px;
  background-color: white;
  outline: none !important;
  &:hover {
    background-color: #efefef;
  }
`;

const LogoDesktop = styled.img`
  width: 164px;
  height: 20px;
  cursor:pointer;
  ${media.small`
    width: 164px;
    height: 32px;
  `}
  font-size: 24px;
`;

const LogoMobile = styled.img`
  width: 32px;
  height: 32px;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
  font-size: 24px;
`;

const SignOut = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  display: ${(props) => props.display};
  position: relative;
  flex-direction: column;
  top: 80px;
  left: -40px;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  width: 120px;
  font-size: 16px;
  /* transition-duration:160ms; */
  text-align: center;
  div {
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: #ddd;
    }
  }
`;

const NavLink = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 25px;
  align-items: center;
  color: ${(props) => (props.isactive ? "#0099FF" : "#000000")};
  cursor: pointer;
  &:hover {
    color: #0099ff;
  }
  ${media.small`
    font-size: 12px;
    margin: 0px 2.5px;
  `}
`;

const NavBarStart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const NavBarMid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  ${media.small`
    display: none;
  `}
`;

const NavBarEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const MobileMenu = styled(HambergurMenu)`
  margin-right: 10px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
`;

const GreetingMessageDesktop = styled.span`
  font-size: 16px;
  font-weight: 500;
  ${media.small`
    display: none;
  `}
`;

const GreetingMessageMobile = styled.span`
  font-size: 16px;
  font-weight: 500;
  ${media.small`
    font-size: 14px;
  `}
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  height: 100%;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

const IconArrow = styled(ArrowIcon)`
  height: 20px;
  width: 20px;
  margin-left: 5px;
`;
