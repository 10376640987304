import { useEffect, useState } from "react";
import styled from "styled-components";
import TabOptions from "../../TabOptions";
import MultiSelectGraph from "../../MultiSelectGraph";
import { media } from "../../../utils/media";
import { Line, Bar } from "recharts";
import NivoLineGraph from "../../NivoLineGraph";
import NivoRibbonGraph from "../../NivoRibbonGraph";
import NivoHundred from "../../NivoHundred";
import NivoBarGraph from "../../NivoBarGraph";
import { IMAGES } from "../../../constants/constants";
import { cloneDeep } from "lodash";
import moment from "moment";
import CombinedGraph from "./CombinedGraph";
import Toggle from "../../Toggle";

// const getFormattedOptions = (options) => {
//   return options && options.length
//     ? options.map((option, id) => {
//         const subOptions = getFormattedOptions(option.options);
//         return {
//           ...option,
//           selected: false,
//           options: subOptions,
//           isOpen: false,
//         };
//       })
//     : null;
// };

const exchangeKey = (frequency) => {
  if (frequency === "M") {
    return "m_value";
  } else if (frequency === "Q") {
    return "q_value";
  } else if (frequency === "Y") {
    return "cy_value";
  } else if (frequency === "FY") {
    return "fy_value";
  } else {
    return "invalid_period_type";
  }
};

const getNextDate = (date, frequency) => {
  const dataDateObj = moment(date, "YYYY-MM-DD");
  if (frequency === "M") {
    return dataDateObj.add(1, "years").format("YYYY-MM-DD");
  } else if (frequency === "Q") {
    return dataDateObj.add(1, "years").format("YYYY-MM-DD");
  } else if (frequency === "Y" || frequency === "FY") {
    return dataDateObj.add(1, "years").format("YYYY-MM-DD");
  } else {
    return "invalid_period_type";
  }
};

const getArrayAvg = (numbers) => {
  const sum = numbers.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return sum / numbers.length;
};

const getArraySum = (numbers) => {
  return numbers.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
};

const getDataSummation = (
  dataList,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType
) => {
  let formattedData = {};
  const exchange_key = exchangeKey(selectedPeriodType);
  dataList.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      formattedData[labelValue][valObj.date] = formattedData[labelValue][
        valObj.date
      ]
        ? formattedData[labelValue][valObj.date] + valObj.value
        : valObj.value;
    });
  });

  let newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      let value = labelObj[date] / scaleValue;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[date]
          ? exchangeRate[date][exchange_key]
          : 80;
        value = value / exchange;
      }
      labeldata.push({ value: value, date: date });
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const appendOverall = (dataList, aggregation_type, topOption) => {
  let formattedData = {};
  dataList.forEach(({ label, data }) => {
    if (topOption.length === 0 || topOption.includes(label)) {
      data.forEach(({ value, date }) => {
        if (!formattedData[date]) {
          formattedData[date] = [];
        }
        formattedData[date].push(value);
      });
    }
  });

  const overallData = [];
  if (aggregation_type === "sum") {
    Object.keys(formattedData).forEach((date) => {
      overallData.push({ value: getArraySum(formattedData[date]), date: date });
    });
  } else if (aggregation_type === "average") {
    Object.keys(formattedData).forEach((date) => {
      overallData.push({ value: getArrayAvg(formattedData[date]), date: date });
    });
  }

  let updateDataList = dataList;
  updateDataList.push({ label: "Overall", data: overallData });
  return updateDataList;
};

const appendCommonOverall = (
  data,
  dataList1,
  aggregation_type1,
  dataList2,
  aggregation_type2,
  calculation_type,
  scaleValue,
  topOption
) => {
  let combinedData = {};
  dataList1.forEach(({ label, data }) => {
    if (!combinedData[label]) {
      combinedData[label] = {};
    }
    data.forEach(({ value, date }) => {
      if (!combinedData[label][date]) {
        combinedData[label][date] = { value1: null, value2: null };
      }
      combinedData[label][date].value1 = value;
    });
  });

  dataList2.forEach(({ label, data }) => {
    if (!combinedData[label]) {
      combinedData[label] = {};
    }
    data.forEach(({ value, date }) => {
      if (!combinedData[label][date]) {
        combinedData[label][date] = { value1: null, value2: null };
      }
      combinedData[label][date].value2 = value;
    });
  });

  let commonData = {};
  Object.keys(combinedData).forEach((label) => {
    if (topOption.length === 0 || topOption.includes(label)) {
      const labelObj = combinedData[label];
      Object.keys(labelObj).forEach((date) => {
        if (labelObj[date].value1 !== null && labelObj[date].value2 !== null) {
          // only if both data are there
          if (!commonData[date]) {
            commonData[date] = { data1: [], data2: [] };
          }
          commonData[date].data1.push(labelObj[date].value1);
          commonData[date].data2.push(labelObj[date].value2);
        }
      });
    }
  });

  const overallData = [];
  Object.keys(commonData).forEach((date) => {
    let value1 = null;
    let value2 = null;

    if (aggregation_type1 === "sum" || aggregation_type1 === "average of sum") {
      value1 = commonData[date].data1.length
        ? getArraySum(commonData[date].data1)
        : null;
    } else if (
      aggregation_type1 === "average" ||
      aggregation_type1 === "sum of average"
    ) {
      value1 = commonData[date].data1.length
        ? getArrayAvg(commonData[date].data1)
        : null;
    }

    if (aggregation_type2 === "sum" || aggregation_type2 === "average of sum") {
      value2 = commonData[date].data2.length
        ? getArraySum(commonData[date].data2)
        : null;
    } else if (
      aggregation_type2 === "average" ||
      aggregation_type2 === "sum of average"
    ) {
      value2 = commonData[date].data2.length
        ? getArrayAvg(commonData[date].data2)
        : null;
    }

    if (value1 !== null && value2 !== null) {
      if (calculation_type === "division" && value2 !== 0) {
        overallData.push({ value: value1 / (value2 * scaleValue), date: date });
      } else if (calculation_type === "division_percentage" && value2 !== 0) {
        overallData.push({
          value: (value1 * 100) / (value2 * scaleValue),
          date: date,
        });
      } else if (calculation_type === "multiplication") {
        overallData.push({ value: (value1 * value2) / scaleValue, date: date });
      }
    }
  });

  let updateDataList = data;
  updateDataList.push({ label: "Overall", data: overallData });
  return updateDataList;
};

const getYoyData = (
  dataList,
  dataListMonth,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType,
  aggregation_type,
  topOption,
  include_overall
) => {
  let AbsoluteData = dataList.filter((data) => data.data_type === "Absolute");
  let AbsoluteDataMonth = dataListMonth.filter(
    (data) => data.data_type === "Absolute"
  );
  let formattedData = {};
  const exchange_key = exchangeKey(selectedPeriodType);
  AbsoluteData.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = 0;
      }
      let value = valObj.value;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[valObj.date]
          ? exchangeRate[valObj.date][exchange_key]
          : 80;
        value = value / exchange;
      }
      formattedData[labelValue][valObj.date] =
        formattedData[labelValue][valObj.date] + value;
    });
  });

  let formattedDataMonth = {};
  const exchange_key_month = exchangeKey("M");
  AbsoluteDataMonth.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedDataMonth[labelValue]) {
      formattedDataMonth[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedDataMonth[labelValue][valObj.date]) {
        formattedDataMonth[labelValue][valObj.date] = 0;
      }
      let value = valObj.value;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[valObj.date]
          ? exchangeRate[valObj.date][exchange_key_month]
          : 80;
        value = value / exchange;
      }
      formattedDataMonth[labelValue][valObj.date] =
        formattedDataMonth[labelValue][valObj.date] + value;
    });
  });

  let all_dates_month = Object.keys(formattedDataMonth).flatMap((label) =>
    Object.keys(formattedDataMonth[label])
  );
  const max_date_month = all_dates_month.sort((a, b) =>
    moment(b).diff(moment(a))
  )[0];

  let all_dates = Object.keys(formattedData).flatMap((label) =>
    Object.keys(formattedData[label])
  );
  const max_date = all_dates.sort((a, b) => moment(b).diff(moment(a)))[0];

  let overallAbsoluteData = {};
  const yoyFormattedData = [];
  Object.keys(formattedData).forEach((labelValue) => {
    const labelObj = formattedData[labelValue];
    const labeldata = [];
    const min_date = Object.keys(labelObj).sort((a, b) =>
      moment(a).diff(moment(b))
    )[0];
    Object.keys(labelObj).forEach((prevDate) => {
      const currDate = getNextDate(prevDate, selectedPeriodType);
      if (
        ((selectedPeriodType === "FY" || selectedPeriodType === "Y") &&
          prevDate === min_date) ||
        prevDate === max_date
      ) {
      } else if (
        (selectedPeriodType === "FY" ||
          selectedPeriodType === "Y" ||
          selectedPeriodType === "Q") &&
        currDate === max_date
      ) {
        // Create date pairs based on the period type
        if (Object.keys(formattedDataMonth).includes(labelValue)) {
          let datePairs = [];
          if (selectedPeriodType === "FY" || selectedPeriodType === "Y") {
            let prevDateMoment = moment(prevDate);
            let nextDateMoment = moment(currDate);
            // let endDateMoment = moment(currDate).add(1, 'year').subtract(1, 'month');

            while (prevDateMoment.isBefore(moment(currDate))) {
              datePairs.push({
                prev: prevDateMoment.format("YYYY-MM-DD"),
                curr: nextDateMoment.format("YYYY-MM-DD"),
              });
              prevDateMoment.add(1, "month");
              nextDateMoment.add(1, "month");
            }
          } else if (selectedPeriodType === "Q") {
            let prevQuarterStart = moment(prevDate).startOf("quarter");
            let currQuarterStart = moment(currDate).startOf("quarter");

            for (let i = 0; i < 3; i++) {
              datePairs.push({
                prev: prevQuarterStart.format("YYYY-MM-DD"),
                curr: currQuarterStart.format("YYYY-MM-DD"),
              });
              prevQuarterStart.add(1, "month");
              currQuarterStart.add(1, "month");
            }
          }

          // Aggregate data for the relevant periods
          let totalPrev = 0;
          let totalCurr = 0;

          let totalPrevOverall = 0;
          let totalCurrOverall = 0;
          let totalPrevOverallFound = false;
          let totalCurrOverallFound = false;
          for (let pair of datePairs) {
            if (moment(pair.curr).isSameOrBefore(max_date_month)) {
              let prevValue = formattedDataMonth[labelValue][pair.prev];
              let currValue = formattedDataMonth[labelValue][pair.curr];

              if (prevValue !== undefined && currValue !== undefined) {
                totalPrev += prevValue;
                totalCurr += currValue;
              }

              if (prevValue !== undefined) {
                totalPrevOverall += prevValue;
                totalPrevOverallFound = true;
              }
              if (currValue !== undefined) {
                totalCurrOverall += currValue;
                totalCurrOverallFound = true;
              }
            }
          }

          // Calculate YoY growth
          if (totalPrev !== 0) {
            let yoy_value =
              ((totalCurr - totalPrev) / Math.abs(totalPrev)) * 100; //
            labeldata.push({
              value: yoy_value,
              date: currDate,
            });
          }

          // YoY data for Overall
          if (
            datePairs.length &&
            (topOption.length === 0 || topOption.includes(labelValue))
          ) {
            if (!overallAbsoluteData[currDate]) {
              overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
            }
            if (totalCurrOverallFound) {
              overallAbsoluteData[currDate].currValue.push(totalCurrOverall);
            }
            if (totalPrevOverallFound) {
              overallAbsoluteData[currDate].prevValue.push(totalPrevOverall);
            }
          }
        }
      } else {
        // Calculate YoY growth
        if (currDate in labelObj && labelObj[prevDate]) {
          let valuePrev = labelObj[prevDate];
          let valueCurr = labelObj[currDate];
          let yoy_value = ((valueCurr - valuePrev) / Math.abs(valuePrev)) * 100; //
          labeldata.push({
            value: yoy_value,
            date: currDate,
          });
        }

        // YoY data for Overall
        if (topOption.length === 0 || topOption.includes(labelValue)) {
          if (!overallAbsoluteData[currDate]) {
            overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
          }
          if (currDate in labelObj) {
            overallAbsoluteData[currDate].currValue.push(labelObj[currDate]);
          }
          if (prevDate in labelObj) {
            // always true anyways
            overallAbsoluteData[currDate].prevValue.push(labelObj[prevDate]);
          }
        }
      }
    });
    if (labeldata.length) {
      yoyFormattedData.push({
        label: labelValue,
        data: labeldata,
      });
    }
  });

  const overallData = [];
  Object.keys(overallAbsoluteData).forEach((date) => {
    let totalCurrValue = null;
    let totalPrevValue = null;

    if (aggregation_type === "sum" || aggregation_type === "average of sum") {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArraySum(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArraySum(overallAbsoluteData[date].prevValue)
        : null;
    } else if (
      aggregation_type === "average" ||
      aggregation_type === "sum of average"
    ) {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArrayAvg(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArrayAvg(overallAbsoluteData[date].prevValue)
        : null;
    }

    if (totalCurrValue !== null && totalPrevValue !== null) {
      overallData.push({
        value:
          ((totalCurrValue - totalPrevValue) / Math.abs(totalPrevValue)) * 100, //
        date: date,
      });
    }
  });
  if (include_overall && overallData.length) {
    yoyFormattedData.push({
      label: "Overall",
      data: overallData,
    });
  }
  return yoyFormattedData;
};

const getYoyCustomData = (
  absoluteData,
  absoluteDataMonth,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType,
  aggregation_type,
  topOption,
  include_overall
) => {
  let formattedData = {};
  absoluteData.forEach(({ data, label }) => {
    if (!formattedData[label]) {
      formattedData[label] = {};
    }
    data.forEach((valObj) => {
      if (!formattedData[label][valObj.date]) {
        formattedData[label][valObj.date] = 0;
      }
      let value = valObj.value;
      formattedData[label][valObj.date] =
        formattedData[label][valObj.date] + value;
    });
  });

  let formattedDataMonth = {};
  absoluteDataMonth.forEach(({ data, label }) => {
    if (!formattedDataMonth[label]) {
      formattedDataMonth[label] = {};
    }
    data.forEach((valObj) => {
      if (!formattedDataMonth[label][valObj.date]) {
        formattedDataMonth[label][valObj.date] = 0;
      }
      let value = valObj.value;
      formattedDataMonth[label][valObj.date] =
        formattedDataMonth[label][valObj.date] + value;
    });
  });

  let all_dates_month = Object.keys(formattedDataMonth).flatMap((label) =>
    Object.keys(formattedDataMonth[label])
  );
  const max_date_month = all_dates_month.sort((a, b) =>
    moment(b).diff(moment(a))
  )[0];

  let all_dates = Object.keys(formattedData).flatMap((label) =>
    Object.keys(formattedData[label])
  );
  const max_date = all_dates.sort((a, b) => moment(b).diff(moment(a)))[0];

  let overallAbsoluteData = {};
  const yoyFormattedData = [];
  Object.keys(formattedData).forEach((labelValue) => {
    const labelObj = formattedData[labelValue];
    const labeldata = [];
    const min_date = Object.keys(labelObj).sort((a, b) =>
      moment(a).diff(moment(b))
    )[0];
    Object.keys(labelObj).forEach((prevDate) => {
      const currDate = getNextDate(prevDate, selectedPeriodType);
      if (
        ((selectedPeriodType === "FY" || selectedPeriodType === "Y") &&
          prevDate === min_date) ||
        prevDate === max_date
      ) {
      } else if (
        (selectedPeriodType === "FY" ||
          selectedPeriodType === "Y" ||
          selectedPeriodType === "Q") &&
        currDate === max_date
      ) {
        // Create date pairs based on the period type
        if (Object.keys(formattedDataMonth).includes(labelValue)) {
          let datePairs = [];
          if (selectedPeriodType === "FY" || selectedPeriodType === "Y") {
            let prevDateMoment = moment(prevDate);
            let nextDateMoment = moment(currDate);
            // let endDateMoment = moment(currDate).add(1, 'year').subtract(1, 'month');

            while (prevDateMoment.isBefore(moment(currDate))) {
              datePairs.push({
                prev: prevDateMoment.format("YYYY-MM-DD"),
                curr: nextDateMoment.format("YYYY-MM-DD"),
              });
              prevDateMoment.add(1, "month");
              nextDateMoment.add(1, "month");
            }
          } else if (selectedPeriodType === "Q") {
            let prevQuarterStart = moment(prevDate).startOf("quarter");
            let currQuarterStart = moment(currDate).startOf("quarter");

            for (let i = 0; i < 3; i++) {
              datePairs.push({
                prev: prevQuarterStart.format("YYYY-MM-DD"),
                curr: currQuarterStart.format("YYYY-MM-DD"),
              });
              prevQuarterStart.add(1, "month");
              currQuarterStart.add(1, "month");
            }
          }

          // Aggregate data for the relevant periods
          let totalPrev = 0;
          let totalCurr = 0;

          let totalPrevOverall = 0;
          let totalCurrOverall = 0;
          let totalPrevOverallFound = false;
          let totalCurrOverallFound = false;
          for (let pair of datePairs) {
            if (moment(pair.curr).isSameOrBefore(max_date_month)) {
              let prevValue = formattedDataMonth[labelValue][pair.prev];
              let currValue = formattedDataMonth[labelValue][pair.curr];

              if (prevValue !== undefined && currValue !== undefined) {
                totalPrev += prevValue;
                totalCurr += currValue;
              }

              if (prevValue !== undefined) {
                totalPrevOverall += prevValue;
                totalPrevOverallFound = true;
              }
              if (currValue !== undefined) {
                totalCurrOverall += currValue;
                totalCurrOverallFound = true;
              }
            }
          }

          // Calculate YoY growth
          if (totalPrev !== 0) {
            let yoy_value =
              ((totalCurr - totalPrev) / Math.abs(totalPrev)) * 100; //
            labeldata.push({
              value: yoy_value,
              date: currDate,
            });
          }

          // YoY data for Overall
          if (
            datePairs.length &&
            (topOption.length === 0 || topOption.includes(labelValue))
          ) {
            if (!overallAbsoluteData[currDate]) {
              overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
            }
            if (totalCurrOverallFound) {
              overallAbsoluteData[currDate].currValue.push(totalCurrOverall);
            }
            if (totalPrevOverallFound) {
              overallAbsoluteData[currDate].prevValue.push(totalPrevOverall);
            }
          }
        }
      } else {
        // Calculate YoY growth
        if (currDate in labelObj && labelObj[prevDate]) {
          let valuePrev = labelObj[prevDate];
          let valueCurr = labelObj[currDate];
          let yoy_value = ((valueCurr - valuePrev) / Math.abs(valuePrev)) * 100; //
          labeldata.push({
            value: yoy_value,
            date: currDate,
          });
        }

        // YoY data for Overall
        if (topOption.length === 0 || topOption.includes(labelValue)) {
          if (!overallAbsoluteData[currDate]) {
            overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
          }
          if (currDate in labelObj) {
            overallAbsoluteData[currDate].currValue.push(labelObj[currDate]);
          }
          if (prevDate in labelObj) {
            // always true anyways
            overallAbsoluteData[currDate].prevValue.push(labelObj[prevDate]);
          }
        }
      }
    });
    if (labeldata.length) {
      yoyFormattedData.push({
        label: labelValue,
        data: labeldata,
      });
    }
  });

  const overallData = [];
  Object.keys(overallAbsoluteData).forEach((date) => {
    let totalCurrValue = null;
    let totalPrevValue = null;

    if (aggregation_type === "sum" || aggregation_type === "average of sum") {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArraySum(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArraySum(overallAbsoluteData[date].prevValue)
        : null;
    } else if (
      aggregation_type === "average" ||
      aggregation_type === "sum of average"
    ) {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArrayAvg(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArrayAvg(overallAbsoluteData[date].prevValue)
        : null;
    }

    if (totalCurrValue !== null && totalPrevValue !== null) {
      overallData.push({
        value:
          ((totalCurrValue - totalPrevValue) / Math.abs(totalPrevValue)) * 100, //
        date: date,
      });
    }
  });
  if (include_overall && overallData.length) {
    yoyFormattedData.push({
      label: "Overall",
      data: overallData,
    });
  }
  return yoyFormattedData;
};

const getDataAverage = (
  dataList,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType
) => {
  let formattedData = {};
  const exchange_key = exchangeKey(selectedPeriodType);
  dataList.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = [];
      }
      formattedData[labelValue][valObj.date].push(valObj.value);
    });
  });

  const newformattedData = Object.keys(formattedData).map((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      let value = getArrayAvg(labelObj[date]) / scaleValue;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[date]
          ? exchangeRate[date][exchange_key]
          : 80;
        value = value / exchange;
      }
      labeldata.push({
        value: value,
        date: date,
      });
    });
    return {
      label: key,
      data: labeldata,
    };
  });
  return newformattedData;
};

const getDataDivision = (data1, data2, scaleValue) => {
  let formattedData = {};
  data1.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value1"] = valObj.value;
    });
  });

  data2.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value2"] = valObj.value;
    });
  });

  const newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      if (labelObj[date].value1 !== null && labelObj[date].value2) {
        labeldata.push({
          value: labelObj[date].value1 / (labelObj[date].value2 * scaleValue),
          date: date,
        });
      }
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const getDataDivisionPercentage = (data1, data2, scaleValue) => {
  let formattedData = {};
  data1.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value1"] = valObj.value;
    });
  });

  data2.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value2"] = valObj.value;
    });
  });

  const newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      if (labelObj[date].value2) {
        labeldata.push({
          value:
            (labelObj[date].value1 / (labelObj[date].value2 * scaleValue)) *
            100,
          date: date,
        });
      }
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const getDataMultiplication = (data1, data2, scaleValue) => {
  let formattedData = {};
  data1.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value1"] = valObj.value;
    });
  });

  data2.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value2"] = valObj.value;
    });
  });

  const newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      if (labelObj[date].value2 != null && labelObj[date].value1 != null) {
        labeldata.push({
          value: (labelObj[date].value1 * labelObj[date].value2) / scaleValue,
          date: date,
        });
      }
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const Page = ({
  page = {
    report: "Horizontals",
    report_id: 70,
    page: 1,
    slicer: [],
    filter: [],
    data_list: [],
  },
  is_usd = false,
  custUsdExchange,
  exchangeRate,
  yearPeriod = "CY",
  is_admin = false,
}) => {
  const [allDataList, setAllDataList] = useState([]);
  const [allSlicer, setAllSlicer] = useState([]);
  const [selectedSlicer, setSelectedSlicer] = useState(null);
  const [selectedSubSlicer, setSelectedSubSlicer] = useState(null);
  const [selectedPeriodType, setSeletedPeriodType] = useState(null);
  const [selectedDataType, setSeletedDataType] = useState(null);
  const [includeOverall, setIncludeOverall] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [legendFilter, setLegendFilter] = useState(null);
  const [legendOther, setLegendOther] = useState(null);

  const [isCommonFilter, setCommonFilter] = useState(false);
  const [allFilter, setAllfilter] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState("");
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [debugEnabled, setDebugEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (legendFilter?.top_option?.length) {
      setLegendOther(
        legendFilter.top_option[legendFilter.top_option.length - 1]
      );
    }
  }, [legendFilter]);

  useEffect(() => {
    if (debugEnabled) {
      console.log("Graph Data -", graphData);
    }
  }, [debugEnabled, graphData]);

  useEffect(() => {
    try {
      setAllDataList(page.data_list);
      setAllSlicer(page.slicer.filter((slicer) => slicer));
      const visibleSlicers = page.slicer.filter(
        (slicer) => slicer && !(slicer.is_hidden || false)
      );
      if (visibleSlicers.length > 0) {
        setError("");
        let missingPageFilter = true;
        if (page.filter) {
          missingPageFilter = false;
          setCommonFilter(true);
          setAllfilter(page.filter);
          setLegendFilter(null);
          page.filter.forEach((filter) => {
            if (filter.is_legend) {
              setLegendFilter(filter);
            }
          });
        } else {
          setCommonFilter(false);
        }

        const firstSlicer = visibleSlicers[0];
        setSelectedSlicer(firstSlicer);
        const visibleSubSlicers =
          firstSlicer.sub_slicer?.filter(
            (sub_slicer) => sub_slicer && !(sub_slicer.is_hidden || false)
          ) || [];
        if (visibleSubSlicers?.length > 0) {
          const firstSubSlicer = visibleSubSlicers[0];
          setSelectedSubSlicer(firstSubSlicer);
          setSeletedPeriodType(
            firstSubSlicer.period_type.filter((a) => a !== "FY").length > 0
              ? firstSubSlicer.period_type.filter((a) => a !== "FY")[0]
              : null
          );
          setSeletedDataType(
            firstSubSlicer.data_type.length > 0
              ? firstSubSlicer.data_type[0]
              : null
          );
          setIncludeOverall(
            firstSubSlicer ? firstSubSlicer?.include_overall || false : false
          );

          if (missingPageFilter) {
            setLegendFilter(null);
            if (firstSubSlicer.filter) {
              setAllfilter(firstSubSlicer.filter);
              firstSubSlicer.filter.forEach((filter) => {
                if (filter.is_legend) {
                  setLegendFilter(filter);
                }
              });
            } else {
              setAllfilter([]);
            }
          }
        } else {
          setSelectedSubSlicer(null);
          setSeletedPeriodType(
            firstSlicer.period_type.filter((a) => a !== "FY").length > 0
              ? firstSlicer.period_type.filter((a) => a !== "FY")[0]
              : null
          );
          setSeletedDataType(
            firstSlicer.data_type.length > 0 ? firstSlicer.data_type[0] : null
          );
          setIncludeOverall(
            firstSlicer ? firstSlicer?.include_overall || false : false
          );
          if (missingPageFilter) {
            if (firstSlicer.filter) {
              setAllfilter(firstSlicer.filter);
              setLegendFilter(null);
              firstSlicer.filter.forEach((filter) => {
                if (filter.is_legend) {
                  setLegendFilter(filter);
                }
              });
            } else {
              setAllfilter([]);
            }
          }
        }
        setIsLoading(false);
      } else {
        console.error("No visible Slicer Found");
        setError(
          "Oops! Something went wrong. Please report this issue to your POC."
        );
        setIsLoading(false);
      }
    } catch (err) {
      console.error(`Error in page render, err-${err}`);
      setError(
        "Oops! Something went wrong. Please report this issue to your POC."
      );
      setIsLoading(false);
    }
  }, [page]);

  const onSlicerChange = (value) => {
    try {
      setError("");
      const newSlicer = allSlicer.filter((slicer) => slicer.name === value)[0];
      setSelectedSlicer(newSlicer);
      if (newSlicer.sub_slicer?.length > 0) {
        let firstSubSlicer = null;
        if (
          newSlicer.sub_slicer.filter(
            (subSlicer) =>
              !(subSlicer?.is_hidden || false) &&
              subSlicer.name === selectedSubSlicer.name
          ).length
        ) {
          //already selected
          firstSubSlicer = newSlicer.sub_slicer.filter(
            (subSlicer) => subSlicer.name === selectedSubSlicer.name
          )[0];
        } else {
          firstSubSlicer = newSlicer.sub_slicer.filter(
            (subSlicer) => !(subSlicer?.is_hidden || false)
          )[0];
        }
        setSelectedSubSlicer(firstSubSlicer);
        setIncludeOverall(
          firstSubSlicer ? firstSubSlicer?.include_overall || false : false
        );
        if (firstSubSlicer.period_type.filter((a) => a !== "FY").length > 0) {
          if (
            firstSubSlicer.period_type.filter(
              (periodType) => periodType === selectedPeriodType
            ).length === 0
          ) {
            setSeletedPeriodType(
              firstSubSlicer.period_type.filter((a) => a !== "FY")[0]
            );
          }
        } else {
          setSeletedPeriodType(null);
        }
        if (firstSubSlicer.data_type.length > 0) {
          if (
            firstSubSlicer.data_type.filter(
              (dataType) => dataType.name === selectedDataType.name
            ).length
          ) {
            //already selected
            setSeletedDataType(
              firstSubSlicer.data_type.filter(
                (dataType) => dataType.name === selectedDataType.name
              )[0]
            );
          } else {
            setSeletedDataType(firstSubSlicer.data_type[0]);
          }
        } else {
          setSeletedDataType(null);
        }
        if (!isCommonFilter) {
          setSelectedOptions([]);
          if (firstSubSlicer.filter) {
            setAllfilter(firstSubSlicer.filter);
            const legendFilters = firstSubSlicer.filter.filter(
              (filter) => filter.is_legend
            );
            if (legendFilters.length) {
              setLegendFilter(legendFilters[0]);
            } else {
              setLegendFilter(null);
            }
          } else {
            setLegendFilter(null);
            setAllfilter([]);
          }
        }
      } else {
        setSelectedSubSlicer(null);
        setIncludeOverall(
          newSlicer ? newSlicer?.include_overall || false : false
        );
        if (newSlicer.period_type.filter((a) => a !== "FY").length > 0) {
          if (
            newSlicer.period_type.filter(
              (periodType) => periodType === selectedPeriodType
            ).length === 0
          ) {
            setSeletedPeriodType(
              newSlicer.period_type.filter((a) => a !== "FY")[0]
            );
          }
        } else {
          setSeletedPeriodType(null);
        }
        if (newSlicer.data_type.length > 0) {
          if (
            newSlicer.data_type.filter(
              (dataType) => dataType.name === selectedDataType.name
            ).length
          ) {
            //already selected
            setSeletedDataType(
              newSlicer.data_type.filter(
                (dataType) => dataType.name === selectedDataType.name
              )[0]
            );
          } else {
            setSeletedDataType(newSlicer.data_type[0]);
          }
        } else {
          setSeletedDataType(null);
        }
        if (!isCommonFilter) {
          setSelectedOptions([]);
          if (newSlicer.filter) {
            setAllfilter(newSlicer.filter);
            const legendFilters = newSlicer.filter.filter(
              (filter) => filter.is_legend
            );
            if (legendFilters.length) {
              setLegendFilter(legendFilters[0]);
            } else {
              setLegendFilter(null);
            }
          } else {
            setAllfilter([]);
            setLegendFilter(null);
          }
        }
      }
    } catch (err) {
      console.error(`Error in onSlicerChange value=${value}, err-${err}`);
      setError(
        "Oops! Something went wrong. Please report this issue to your POC."
      );
    }
  };

  const onSubSlicerChange = (value) => {
    const newSubSlicer = selectedSlicer.sub_slicer.filter(
      (sub_slicer) => sub_slicer.name === value
    )[0];
    setSelectedSubSlicer(newSubSlicer);
    setIncludeOverall(
      newSubSlicer ? newSubSlicer?.include_overall || false : false
    );
    if (newSubSlicer.period_type.filter((a) => a !== "FY").length > 0) {
      if (
        newSubSlicer.period_type.filter(
          (periodType) => periodType === selectedPeriodType
        ).length === 0
      ) {
        setSeletedPeriodType(
          newSubSlicer.period_type.filter((a) => a !== "FY")[0]
        );
      }
    } else {
      setSeletedPeriodType(null);
    }
    if (newSubSlicer.data_type.length > 0) {
      if (
        newSubSlicer.data_type.filter(
          (dataType) => dataType === selectedDataType
        ).length === 0
      ) {
        setSeletedDataType(newSubSlicer.data_type[0]);
      }
    } else {
      setSeletedDataType(null);
    }
    if (!isCommonFilter) {
      setSelectedOptions([]);
      if (newSubSlicer.filter) {
        setAllfilter(newSubSlicer.filter);
        const legendFilters = newSubSlicer.filter.filter(
          (filter) => filter.is_legend
        );
        if (legendFilters.length) {
          setLegendFilter(legendFilters[0]);
        } else {
          setLegendFilter(null);
        }
      } else {
        setLegendFilter(null);
        setAllfilter([]);
      }
    }
  };

  const onDataTypeChange = (value) => {
    setSeletedDataType(value);
  };

  const onPeriodTypeChange = (value) => {
    setSeletedPeriodType(value);
  };

  const onSelectedOptions = (updatedOptions, type) => {
    let newUpdatedOptions = updatedOptions;
    if (
      legendFilter?.type === type &&
      legendOther &&
      newUpdatedOptions.includes(legendOther)
    ) {
      // legend and Other selected
      const currentFilter = allFilter.filter(
        (filter) => filter.type === type
      )[0];
      currentFilter.options
        .filter((opt) => !currentFilter.top_option.includes(opt.name))
        .forEach((opt) => {
          newUpdatedOptions.push(opt.name);
        });
    } else if (
      legendFilter?.type === type &&
      legendFilter?.top_option?.length
    ) {
      // no Other selected but legend
      newUpdatedOptions = newUpdatedOptions.filter((opt) =>
        legendFilter.top_option.includes(opt)
      );
    }
    let updatedFilterObj = { ...selectedOptions };
    updatedFilterObj[type] = newUpdatedOptions;
    setSelectedOptions(updatedFilterObj);
  };

  const applyFilters = (dataList, filterResult) => {
    const filters = filterResult.reduce((acc, filter) => {
      acc[filter.key] = new Set(filter.value);
      return acc;
    }, {});

    return dataList.filter((data) => {
      return Object.keys(filters).every((key) => {
        return !filters[key].size || filters[key].has(data[key]);
      });
    });
  };

  const sortDataInLegendOrder = (all_data) => {
    if (legendFilter) {
      let id_order = [];
      if (legendFilter.top_option) {
        id_order = legendFilter.top_option;
      } else {
        id_order = legendFilter.options.map((opt) => opt.name);
      }
      const sorted_data = all_data.sort((a, b) => {
        const indexA = id_order.indexOf(a.label);
        const indexB = id_order.indexOf(b.label);
        if (indexA === -1 && indexB === -1) {
          return 0;
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }
        return indexA - indexB;
      });
      return sorted_data;
    } else {
      return all_data;
    }
  };

  const getFilterVisibleOptions = (filter) => {
    if (filter.top_option?.length) {
      const top_option = filter.top_option;
      let visibleOptions = filter.options.filter((obj) =>
        filter.top_option.includes(obj.name)
      );
      visibleOptions.push({
        name: legendFilter.top_option[legendFilter.top_option.length - 1],
      });

      const sorted_data = visibleOptions.sort((a, b) => {
        const indexA = top_option.indexOf(a.name);
        const indexB = top_option.indexOf(b.name);
        if (indexA === -1 && indexB === -1) {
          return 0;
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }
        return indexA - indexB;
      });
      return sorted_data;
    } else {
      return filter.options;
    }
  };

  const listAllNestedNames = (filterObject) => {
    let names = [];
    function extractNames(objList) {
      for (let obj of objList) {
        if (obj.name) {
          names.push(obj.name);
        }
        if (obj.options && obj.options.length > 0) {
          extractNames(obj.options);
        }
      }
    }

    extractNames(filterObject);
    return names;
  };

  useEffect(() => {
    try {
      if (isLoading) {
        return;
      }
      const data_type =
        selectedSubSlicer?.data_type || selectedSlicer?.data_type;
      if (!data_type) {
        console.error("Datatypes not found, page", page.pane_name);
        setError(
          "Oops! Something went wrong. Please report this issue to your POC."
        );
        return;
      }
      setError("");
      if (allDataList.length === 0) {
        setGraphData([]);
        return;
      }

      let make_calulation = false;
      if (selectedSubSlicer) {
        make_calulation = selectedSubSlicer.custom_data?.skip_data;
      } else if (selectedSlicer) {
        make_calulation = selectedSlicer.custom_data?.skip_data;
      } else {
        return;
      }
      if (make_calulation) {
        // custom calculation
        let is_subslicer = false;
        let dependeds = [];
        let calculation_type = "";
        let is_result_scaled = false;
        let is_result_currency = false;
        if (selectedSubSlicer) {
          debugEnabled &&
            console.log(
              "SubSlicer:",
              selectedSubSlicer.name,
              selectedSubSlicer.custom_data
            );
          is_subslicer = true;
          dependeds = selectedSubSlicer.custom_data.sub_slicer_list || [];
          if (dependeds.length === 0) {
            throw new Error(
              `sub_slicer_list not found in custom_data - ${JSON.stringify(
                selectedSubSlicer.custom_data
              )}`
            );
          }
          calculation_type = selectedSubSlicer.custom_data.calculation_type;
          is_result_scaled = selectedSubSlicer?.is_scaled || false;
          is_result_currency = selectedSubSlicer?.is_currency || false;
        } else {
          debugEnabled &&
            console.log(
              "Slicer:",
              selectedSlicer.name,
              selectedSlicer.custom_data
            );
          dependeds = selectedSlicer.custom_data.slicer_list || [];
          if (dependeds.length === 0) {
            throw new Error(
              `sub_slicer_list not found in custom_data - ${JSON.stringify(
                selectedSlicer.custom_data
              )}`
            );
          }
          calculation_type = selectedSlicer.custom_data.calculation_type;
          is_result_scaled = selectedSlicer?.is_scaled || false;
          is_result_currency = selectedSlicer?.is_currency || false;
        }
        if (dependeds.length !== 2) {
          setGraphData([]);
          return;
        }
        let data1 = [];
        let data2 = [];

        let filtered_object = Object.keys(selectedOptions)
          .filter((key) => selectedOptions[key].length > 0)
          .map((key) => ({ key, value: selectedOptions[key] }));

        if (selectedDataType) {
          filtered_object.push({
            key: "data_type", //
            value: ["Absolute"],
          });
        }
        // this will be used in case of yoy
        let filtered_object_month = cloneDeep(filtered_object);
        filtered_object_month.push({
          key: "period_type",
          value: ["M"],
        });

        if (selectedPeriodType) {
          filtered_object.push({
            key: "period_type",
            value: [
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType,
            ],
          });
        }
        const scaleValue =
          is_result_scaled && selectedDataType.name === "Absolute"
            ? is_usd
              ? 1000000
              : 10000000
            : 1;
        //new calculations
        let filtered_object1;
        let is_currency1;
        let aggregation_type1;

        let filtered_object2;
        let is_currency2;
        let aggregation_type2;

        if (selectedSlicer && is_subslicer) {
          //common slicer
          filtered_object.push({ key: "slicer", value: [selectedSlicer.name] });

          //first filter
          filtered_object1 = cloneDeep(filtered_object);
          const sub_slicer_dep1 = selectedSlicer.sub_slicer.filter(
            (obj) => obj.name === dependeds[0]
          );
          if (sub_slicer_dep1.length === 0) {
            throw new Error(
              `Dependency in custom_data.sub_slicer_list "${
                dependeds[0]
              }" not found in all current subslicer - ${JSON.stringify(
                selectedSlicer.sub_slicer.map((obj) => obj.name)
              )}`
            );
          }
          is_currency1 = sub_slicer_dep1[0].is_currency;
          aggregation_type1 = sub_slicer_dep1[0].aggregation_type || "";
          filtered_object1.push({
            key: "sub_slicer",
            value: [dependeds[0]],
          });

          //second filter
          filtered_object2 = cloneDeep(filtered_object);
          const sub_slicer_dep2 = selectedSlicer.sub_slicer.filter(
            (obj) => obj.name === dependeds[1]
          );
          if (sub_slicer_dep2.length === 0) {
            throw new Error(
              `Dependency in custom_data.sub_slicer_list "${
                dependeds[1]
              }" not found in all current subslicer - ${JSON.stringify(
                selectedSlicer.sub_slicer.map((obj) => obj.name)
              )}`
            );
          }
          is_currency2 = sub_slicer_dep2[0].is_currency;
          aggregation_type2 = sub_slicer_dep2[0].aggregation_type || "";
          filtered_object2.push({
            key: "sub_slicer",
            value: [dependeds[1]],
          });
        } else {
          // only slicer
          //first filter
          filtered_object1 = cloneDeep(filtered_object);
          const slicer_dep1 = allSlicer.filter(
            (obj) => obj.name === dependeds[0]
          );
          if (slicer_dep1.length === 0) {
            throw new Error(
              `Dependency in custom_data.slicer_list "${
                dependeds[0]
              }" not found in all slicer  - ${JSON.stringify(
                allSlicer.map((obj) => obj.name)
              )}`
            );
          }
          is_currency1 = slicer_dep1[0].is_currency;
          aggregation_type1 = slicer_dep1[0].aggregation_type || "";
          filtered_object1.push({
            key: "slicer",
            value: [dependeds[0]],
          });

          //second filter
          filtered_object2 = cloneDeep(filtered_object);
          const slicer_dep2 = allSlicer.filter(
            (obj) => obj.name === dependeds[1]
          );
          if (slicer_dep2.length === 0) {
            throw new Error(
              `Dependency in custom_data.slicer_list "${
                dependeds[1]
              }" not found in all slicer  - ${JSON.stringify(
                allSlicer.map((obj) => obj.name)
              )}`
            );
          }
          is_currency2 = slicer_dep2[0].is_currency;
          aggregation_type2 = slicer_dep2[0].aggregation_type || "";
          filtered_object2.push({
            key: "slicer",
            value: [dependeds[1]],
          });
        }

        //first data
        const filteredDataList1 = applyFilters(allDataList, filtered_object1);
        if (
          aggregation_type1 === "sum" ||
          aggregation_type1 === "average of sum"
        ) {
          data1 = getDataSummation(
            filteredDataList1,
            legendFilter?.type,
            1,
            is_usd,
            is_currency1 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        } else if (
          aggregation_type1 === "average" ||
          aggregation_type1 === "sum of average"
        ) {
          data1 = getDataAverage(
            filteredDataList1,
            legendFilter?.type,
            1,
            is_usd,
            is_currency1 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        }

        //second data
        const filteredDataList2 = applyFilters(allDataList, filtered_object2);
        if (
          aggregation_type2 === "sum" ||
          aggregation_type2 === "average of sum"
        ) {
          data2 = getDataSummation(
            filteredDataList2,
            legendFilter?.type,
            1,
            is_usd,
            is_currency2 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        } else if (
          aggregation_type2 === "average" ||
          aggregation_type2 === "sum of average"
        ) {
          data2 = getDataAverage(
            filteredDataList2,
            legendFilter?.type,
            1,
            is_usd,
            is_currency2 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        }

        //custom data
        let data = [];
        if (calculation_type === "division") {
          data = getDataDivision(data1, data2, scaleValue);
        } else if (calculation_type === "division_percentage") {
          data = getDataDivisionPercentage(data1, data2, scaleValue);
        } else if (calculation_type === "multiplication") {
          data = getDataMultiplication(data1, data2, scaleValue);
        }

        if (
          selectedDataType.name === "100%" ||
          selectedDataType.name === "Absolute"
        ) {
          setGraphData(
            sortDataInLegendOrder(
              includeOverall
                ? appendCommonOverall(
                    data,
                    data1,
                    aggregation_type1,
                    data2,
                    aggregation_type1,
                    calculation_type,
                    scaleValue,
                    legendFilter?.top_option || []
                  )
                : data
            )
          );
        } else {
          //selectedDataType.name === "YOY Growth"
          let filtered_object1_month;
          let is_currency1_month;
          let aggregation_type1_month;

          let filtered_object2_month;
          let is_currency2_month;
          let aggregation_type2_month;

          if (selectedSlicer && is_subslicer) {
            //common slicer
            filtered_object_month.push({
              key: "slicer",
              value: [selectedSlicer.name],
            });

            //first filter
            filtered_object1_month = cloneDeep(filtered_object_month);
            const sub_slicer_dep1 = selectedSlicer.sub_slicer.filter(
              (obj) => obj.name === dependeds[0]
            );
            if (sub_slicer_dep1.length === 0) {
              throw new Error(
                `Dependency in custom_data.sub_slicer_list "${
                  dependeds[0]
                }" not found in all current subslicer - ${JSON.stringify(
                  selectedSlicer.sub_slicer.map((obj) => obj.name)
                )}`
              );
            }
            is_currency1_month = sub_slicer_dep1[0].is_currency;
            aggregation_type1_month = sub_slicer_dep1[0].aggregation_type || "";
            filtered_object1_month.push({
              key: "sub_slicer",
              value: [dependeds[0]],
            });

            //second filter
            filtered_object2_month = cloneDeep(filtered_object_month);
            const sub_slicer_dep2 = selectedSlicer.sub_slicer.filter(
              (obj) => obj.name === dependeds[1]
            );
            if (sub_slicer_dep2.length === 0) {
              throw new Error(
                `Dependency in custom_data.sub_slicer_list "${
                  dependeds[1]
                }" not found in all current subslicer - ${JSON.stringify(
                  selectedSlicer.sub_slicer.map((obj) => obj.name)
                )}`
              );
            }
            is_currency2_month = sub_slicer_dep2[0].is_currency;
            aggregation_type2_month = sub_slicer_dep2[0].aggregation_type || "";
            filtered_object2_month.push({
              key: "sub_slicer",
              value: [dependeds[1]],
            });
          } else {
            // only slicer
            // first filter
            filtered_object1_month = cloneDeep(filtered_object_month);
            const slicer_dep1 = allSlicer.filter(
              (obj) => obj.name === dependeds[0]
            );
            if (slicer_dep1.length === 0) {
              throw new Error(
                `Dependency in custom_data.slicer_list "${
                  dependeds[0]
                }" not found in all slicer  - ${JSON.stringify(
                  allSlicer.map((obj) => obj.name)
                )}`
              );
            }
            is_currency1_month = slicer_dep1[0].is_currency;
            aggregation_type1_month = slicer_dep1[0].aggregation_type || "";
            filtered_object1_month.push({
              key: "slicer",
              value: [dependeds[0]],
            });

            //second filter
            filtered_object2_month = cloneDeep(filtered_object_month);
            const slicer_dep2 = allSlicer.filter(
              (obj) => obj.name === dependeds[1]
            );
            if (slicer_dep2.length === 0) {
              throw new Error(
                `Dependency in custom_data.slicer_list "${
                  dependeds[1]
                }" not found in all slicer  - ${JSON.stringify(
                  allSlicer.map((obj) => obj.name)
                )}`
              );
            }
            is_currency2_month = slicer_dep2[0].is_currency;
            aggregation_type2_month = slicer_dep2[0].aggregation_type || "";
            filtered_object2_month.push({
              key: "slicer",
              value: [dependeds[1]],
            });
          }

          //first data
          const filteredDataList1 = applyFilters(
            allDataList,
            filtered_object1_month
          );
          if (
            aggregation_type1_month === "sum" ||
            aggregation_type1_month === "average of sum"
          ) {
            data1 = getDataSummation(
              filteredDataList1,
              legendFilter?.type,
              1,
              is_usd,
              is_currency1_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          } else if (
            aggregation_type1_month === "average" ||
            aggregation_type1_month === "sum of average"
          ) {
            data1 = getDataAverage(
              filteredDataList1,
              legendFilter?.type,
              1,
              is_usd,
              is_currency1_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          }

          //second data
          const filteredDataList2 = applyFilters(
            allDataList,
            filtered_object2_month
          );
          if (
            aggregation_type2_month === "sum" ||
            aggregation_type2_month === "average of sum"
          ) {
            data2 = getDataSummation(
              filteredDataList2,
              legendFilter?.type,
              1,
              is_usd,
              is_currency2_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          } else if (
            aggregation_type2_month === "average" ||
            aggregation_type2_month === "sum of average"
          ) {
            data2 = getDataAverage(
              filteredDataList2,
              legendFilter?.type,
              1,
              is_usd,
              is_currency2_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          }

          //custom data
          let data_month = [];
          if (calculation_type === "division") {
            data_month = getDataDivision(data1, data2, scaleValue);
          } else if (calculation_type === "division_percentage") {
            data_month = getDataDivisionPercentage(data1, data2, scaleValue);
          } else if (calculation_type === "multiplication") {
            data_month = getDataMultiplication(data1, data2, scaleValue);
          }
          const data_yoy = getYoyCustomData(
            data,
            data_month,
            legendFilter?.type,
            1,
            is_usd,
            false, // is currency
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType,
            "sum", //aggregation_type
            legendFilter?.top_option || [],
            includeOverall
          );
          setGraphData(data_yoy);
        }

        // if (selectedSlicer && is_subslicer) {
        //   //then is has common slicer
        //   filtered_object.push({ key: "slicer", value: [selectedSlicer.name] });

        //   //first filter
        //   let filtered_object1 = cloneDeep(filtered_object);
        //   const sub_slicer_dep1 = selectedSlicer.sub_slicer.filter(
        //     (obj) => obj.name === dependeds[0]
        //   );
        //   if (sub_slicer_dep1.length === 0) {
        //     throw new Error(
        //       `Dependency in custom_data.sub_slicer_list "${
        //         dependeds[0]
        //       }" not found in all current subslicer - ${JSON.stringify(
        //         selectedSlicer.sub_slicer.map((obj) => obj.name)
        //       )}`
        //     );
        //   }
        //   let is_currency1 = sub_slicer_dep1[0].is_currency;
        //   let aggregation_type1 = sub_slicer_dep1[0].aggregation_type || "";
        //   filtered_object1.push({
        //     key: "sub_slicer",
        //     value: [dependeds[0]],
        //   });

        //   //first data
        //   const filteredDataList1 = applyFilters(allDataList, filtered_object1);
        //   if (
        //     aggregation_type1 === "sum" ||
        //     aggregation_type1 === "average of sum"
        //   ) {
        //     data1 = getDataSummation(
        //       filteredDataList1,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency1 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   } else if (
        //     aggregation_type1 === "average" ||
        //     aggregation_type1 === "sum of average"
        //   ) {
        //     data1 = getDataAverage(
        //       filteredDataList1,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency1 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   }

        //   //second filter
        //   let filtered_object2 = cloneDeep(filtered_object);
        //   const sub_slicer_dep2 = selectedSlicer.sub_slicer.filter(
        //     (obj) => obj.name === dependeds[1]
        //   );
        //   if (sub_slicer_dep2.length === 0) {
        //     throw new Error(
        //       `Dependency in custom_data.sub_slicer_list "${
        //         dependeds[1]
        //       }" not found in all current subslicer - ${JSON.stringify(
        //         selectedSlicer.sub_slicer.map((obj) => obj.name)
        //       )}`
        //     );
        //   }
        //   let is_currency2 = sub_slicer_dep2[0].is_currency;
        //   let aggregation_type2 = sub_slicer_dep2[0].aggregation_type || "";
        //   filtered_object2.push({
        //     key: "sub_slicer",
        //     value: [dependeds[1]],
        //   });

        //   //second data
        //   const filteredDataList2 = applyFilters(allDataList, filtered_object2);
        //   if (
        //     aggregation_type2 === "sum" ||
        //     aggregation_type2 === "average of sum"
        //   ) {
        //     data2 = getDataSummation(
        //       filteredDataList2,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency2 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   } else if (
        //     aggregation_type2 === "average" ||
        //     aggregation_type2 === "sum of average"
        //   ) {
        //     data2 = getDataAverage(
        //       filteredDataList2,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency2 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   }

        //   //custom data
        //   if (calculation_type === "division") {
        //     const data = getDataDivision(data1, data2, scaleValue);
        //     setGraphData(
        //       sortDataInLegendOrder(
        //         includeOverall
        //           ? appendCommonOverall(
        //               data,
        //               data1,
        //               aggregation_type1,
        //               data2,
        //               aggregation_type1,
        //               calculation_type,
        //               scaleValue,
        //               legendFilter?.top_option || []
        //             )
        //           : data
        //       )
        //     );
        //   } else if (calculation_type === "division_percentage") {
        //     const data = getDataDivisionPercentage(data1, data2, scaleValue);
        //     setGraphData(
        //       sortDataInLegendOrder(
        //         includeOverall
        //           ? appendCommonOverall(
        //               data,
        //               data1,
        //               aggregation_type1,
        //               data2,
        //               aggregation_type1,
        //               calculation_type,
        //               scaleValue,
        //               legendFilter?.top_option || []
        //             )
        //           : data
        //       )
        //     );
        //   } else if (calculation_type === "multiplication") {
        //     const data = getDataMultiplication(data1, data2, scaleValue);
        //     setGraphData(
        //       sortDataInLegendOrder(
        //         includeOverall
        //           ? appendCommonOverall(
        //               data,
        //               data1,
        //               aggregation_type1,
        //               data2,
        //               aggregation_type1,
        //               calculation_type,
        //               scaleValue,
        //               legendFilter?.top_option || []
        //             )
        //           : data
        //       )
        //     );
        //   } else {
        //     setGraphData([]);
        //   }
        // } else {
        //   // only slicer

        //   //first filter
        //   let filtered_object1 = cloneDeep(filtered_object);
        //   const slicer_dep1 = allSlicer.filter(
        //     (obj) => obj.name === dependeds[0]
        //   );
        //   if (slicer_dep1.length === 0) {
        //     throw new Error(
        //       `Dependency in custom_data.slicer_list "${
        //         dependeds[0]
        //       }" not found in all slicer  - ${JSON.stringify(
        //         allSlicer.map((obj) => obj.name)
        //       )}`
        //     );
        //   }
        //   let is_currency1 = slicer_dep1[0].is_currency;
        //   let aggregation_type1 = slicer_dep1[0].aggregation_type || "";
        //   filtered_object1.push({
        //     key: "slicer",
        //     value: [dependeds[0]],
        //   });

        //   //first data
        //   const filteredDataList1 = applyFilters(allDataList, filtered_object1);
        //   if (
        //     aggregation_type1 === "sum" ||
        //     aggregation_type1 === "average of sum"
        //   ) {
        //     data1 = getDataSummation(
        //       filteredDataList1,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency1 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   } else if (
        //     aggregation_type1 === "average" ||
        //     aggregation_type1 === "sum of average"
        //   ) {
        //     data1 = getDataAverage(
        //       filteredDataList1,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency1 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   }

        //   //second filter
        //   let filtered_object2 = cloneDeep(filtered_object);
        //   const slicer_dep2 = allSlicer.filter(
        //     (obj) => obj.name === dependeds[1]
        //   );
        //   if (slicer_dep2.length === 0) {
        //     throw new Error(
        //       `Dependency in custom_data.slicer_list "${
        //         dependeds[1]
        //       }" not found in all slicer  - ${JSON.stringify(
        //         allSlicer.map((obj) => obj.name)
        //       )}`
        //     );
        //   }
        //   let is_currency2 = slicer_dep2[0].is_currency;
        //   let aggregation_type2 = slicer_dep2[0].aggregation_type || "";
        //   filtered_object2.push({
        //     key: "slicer",
        //     value: [dependeds[1]],
        //   });

        //   //second data
        //   const filteredDataList2 = applyFilters(allDataList, filtered_object2);
        //   if (
        //     aggregation_type2 === "sum" ||
        //     aggregation_type2 === "average of sum"
        //   ) {
        //     data2 = getDataSummation(
        //       filteredDataList2,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency2 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   } else if (
        //     aggregation_type2 === "average" ||
        //     aggregation_type2 === "sum of average"
        //   ) {
        //     data2 = getDataAverage(
        //       filteredDataList2,
        //       legendFilter?.type,
        //       1,
        //       is_usd,
        //       is_currency2 && selectedDataType.name === "Absolute",
        //       custUsdExchange,
        //       exchangeRate,
        //       selectedPeriodType === "Y" && yearPeriod === "FY"
        //         ? "FY"
        //         : selectedPeriodType
        //     );
        //   }

        //   //custom data
        //   if (calculation_type === "division") {
        //     const data = getDataDivision(data1, data2, scaleValue);
        //     setGraphData(
        //       sortDataInLegendOrder(
        //         includeOverall
        //           ? appendCommonOverall(
        //               data,
        //               data1,
        //               aggregation_type1,
        //               data2,
        //               aggregation_type1,
        //               calculation_type,
        //               scaleValue,
        //               legendFilter?.top_option || []
        //             )
        //           : data
        //       )
        //     );
        //   } else if (calculation_type === "division_percentage") {
        //     const data = getDataDivisionPercentage(data1, data2, scaleValue);
        //     setGraphData(
        //       sortDataInLegendOrder(
        //         includeOverall
        //           ? appendCommonOverall(
        //               data,
        //               data1,
        //               aggregation_type1,
        //               data2,
        //               aggregation_type1,
        //               calculation_type,
        //               scaleValue,
        //               legendFilter?.top_option || []
        //             )
        //           : data
        //       )
        //     );
        //   } else if (calculation_type === "multiplication") {
        //     const data = getDataMultiplication(data1, data2, scaleValue);
        //     setGraphData(
        //       sortDataInLegendOrder(
        //         includeOverall
        //           ? appendCommonOverall(
        //               data,
        //               data1,
        //               aggregation_type1,
        //               data2,
        //               aggregation_type1,
        //               calculation_type,
        //               scaleValue,
        //               legendFilter?.top_option || []
        //             )
        //           : data
        //       )
        //     );
        //   } else {
        //     setGraphData([]);
        //   }
        // }

        setGraphType(selectedDataType?.graph_type);
        setScale(is_result_scaled);
        setCurrency(is_result_currency);
      } else {
        // calculation not required
        if (selectedDataType.name === "YOY Growth") {
          let filtered_object = Object.keys(selectedOptions)
            .filter((key) => selectedOptions[key].length > 0)
            .map((key) => ({ key, value: selectedOptions[key] }));
          let aggregation_type = "";
          let is_scaled = false;
          let is_currency = false;
          if (selectedSlicer) {
            filtered_object.push({
              key: "slicer",
              value: [selectedSlicer.name],
            });
            aggregation_type = selectedSlicer.aggregation_type || "";
            is_scaled = selectedSlicer?.is_scaled || false;
            is_currency = selectedSlicer?.is_currency || false;
          }
          if (selectedSubSlicer) {
            filtered_object.push({
              key: "sub_slicer",
              value: [selectedSubSlicer.name],
            });
            aggregation_type = selectedSubSlicer.aggregation_type || "";
            is_scaled = selectedSubSlicer?.is_scaled || false;
            is_currency = selectedSubSlicer?.is_currency || false;
          }
          let filtered_object_month = cloneDeep(filtered_object);
          filtered_object_month.push({
            key: "period_type",
            value: ["M"], // this is fixed recently
          });

          if (selectedPeriodType) {
            filtered_object.push({
              key: "period_type",
              value: [
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType,
              ],
            });
          }

          setGraphType(selectedDataType?.graph_type);
          setScale(is_scaled);
          setCurrency(is_currency);
          const filteredDataList = applyFilters(allDataList, filtered_object);
          const filteredDataListMonth = applyFilters(
            allDataList,
            filtered_object_month
          );
          setGraphData(
            sortDataInLegendOrder(
              getYoyData(
                filteredDataList,
                filteredDataListMonth,
                legendFilter?.type,
                1,
                is_usd,
                is_currency && selectedDataType.name === "Absolute",
                custUsdExchange,
                exchangeRate,
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType,
                aggregation_type,
                legendFilter?.top_option || [],
                includeOverall
              )
            )
          );
        } else {
          let filtered_object = Object.keys(selectedOptions)
            .filter((key) => selectedOptions[key].length > 0)
            .map((key) => ({ key, value: selectedOptions[key] }));
          let aggregation_type = "";
          let is_scaled = false;
          let is_currency = false;
          if (selectedSlicer) {
            filtered_object.push({
              key: "slicer",
              value: [selectedSlicer.name],
            });
            aggregation_type = selectedSlicer.aggregation_type || "";
            is_scaled = selectedSlicer?.is_scaled || false;
            is_currency = selectedSlicer?.is_currency || false;
          }
          if (selectedSubSlicer) {
            filtered_object.push({
              key: "sub_slicer",
              value: [selectedSubSlicer.name],
            });
            aggregation_type = selectedSubSlicer.aggregation_type || "";
            is_scaled = selectedSubSlicer?.is_scaled || false;
            is_currency = selectedSubSlicer?.is_currency || false;
          }
          if (selectedPeriodType) {
            filtered_object.push({
              key: "period_type",
              value: [
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType,
              ],
            });
          }
          if (selectedDataType) {
            filtered_object.push({
              key: "data_type",
              value: [
                selectedDataType.name === "100%"
                  ? "Absolute"
                  : selectedDataType.name,
              ],
            });
          }

          setGraphType(selectedDataType?.graph_type);
          setScale(is_scaled);
          setCurrency(is_currency);
          const filteredDataList = applyFilters(allDataList, filtered_object);
          const scaleValue =
            is_scaled && selectedDataType.name === "Absolute"
              ? is_usd
                ? 1000000
                : 10000000
              : 1;
          if (
            aggregation_type === "sum" ||
            aggregation_type === "average of sum"
          ) {
            const data = getDataSummation(
              filteredDataList,
              legendFilter?.type,
              scaleValue,
              is_usd,
              is_currency && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
            setGraphData(
              sortDataInLegendOrder(
                includeOverall
                  ? appendOverall(data, "sum", legendFilter?.top_option || [])
                  : data
              )
            );
          } else if (
            aggregation_type === "average" ||
            aggregation_type === "sum of average"
          ) {
            const data = getDataAverage(
              filteredDataList,
              legendFilter?.type,
              scaleValue,
              is_usd,
              is_currency && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
            setGraphData(
              sortDataInLegendOrder(
                includeOverall
                  ? appendOverall(
                      data,
                      "average",
                      legendFilter?.top_option || []
                    )
                  : data
              )
            );
          } else {
            setGraphData([]);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching data -", err);
      setError(
        "Oops! Something went wrong. Please report this issue to your POC."
      );
    }
  }, [
    allDataList,
    selectedSlicer,
    selectedSubSlicer,
    selectedPeriodType,
    yearPeriod,
    selectedDataType,
    selectedOptions,
    legendFilter,
    is_usd,
    custUsdExchange,
    exchangeRate,
    allSlicer,
    page.pane_name,
    includeOverall,
  ]);

  return (
    <Wrapper>
      {error.length ? (
        <NoDataMessage>{error}</NoDataMessage>
      ) : (
        <>
          <DesktopView>
            <HeaderRow>
              <PageTitle>
                {selectedSubSlicer
                  ? selectedSubSlicer.title
                  : selectedSlicer
                  ? selectedSlicer.title
                  : ""}
                {isCurrency
                  ? isScale
                    ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Mn" : "Cr"})`
                    : ` (${is_usd ? "USD" : "INR"})`
                  : isScale
                  ? ` (${is_usd ? "Mn" : "Cr"})`
                  : ``}
              </PageTitle>
              <TabSelect
                tabs={
                  selectedSubSlicer
                    ? selectedSubSlicer.period_type.filter((a) => a !== "FY")
                    : selectedSlicer
                    ? selectedSlicer.period_type.filter((a) => a !== "FY")
                    : []
                }
                selectedTab={selectedPeriodType}
                onTabChange={onPeriodTypeChange}
              />
            </HeaderRow>
            <HeaderRow>
              {(() => {
                let visibleSlicers = allSlicer.filter(
                  (slicer) => debugEnabled || !(slicer?.is_hidden || false)
                );
                if (visibleSlicers.length <= 1) {
                  visibleSlicers = [];
                }
                return visibleSlicers.length ? (
                  <TabSelect
                    tabs={
                      visibleSlicers.length > 0
                        ? visibleSlicers.map((slicer) => slicer.name)
                        : []
                    }
                    selectedTab={selectedSlicer ? selectedSlicer.name : null}
                    onTabChange={onSlicerChange}
                  />
                ) : null;
              })()}
              {allFilter
                .filter((filter) => debugEnabled || !filter.is_hidden)
                .map((filter, id) => {
                  let nestedVisibleOptions = getFilterVisibleOptions(filter);
                  const allNestedVisibleNames =
                    listAllNestedNames(nestedVisibleOptions);

                  let filterSelectedOptions = selectedOptions[filter.type]
                    ? selectedOptions[filter.type]
                    : [];
                  filterSelectedOptions = filterSelectedOptions.filter(
                    (value) => allNestedVisibleNames.includes(value)
                  );
                  return (
                    <FilterSelect
                      key={id}
                      options={nestedVisibleOptions}
                      onSelectedChange={(selectedList) => {
                        onSelectedOptions(selectedList, filter.type);
                      }}
                      keyFieldName="name"
                      valueFieldName="name"
                      selectedOptions={filterSelectedOptions}
                      mutileSelect={true}
                      placeholder={`${filter.name} Filter`}
                      mutileSelectText={filter.name}
                      selectAllText="All"
                      selectNoneText="None"
                      borderRadius={0}
                    />
                  );
                })}
              {(() => {
                const allTabs = selectedSubSlicer
                  ? selectedSubSlicer.data_type
                    ? selectedSubSlicer.data_type
                    : []
                  : selectedSlicer
                  ? selectedSlicer.data_type
                    ? selectedSlicer.data_type
                    : []
                  : [];

                return (
                  <TabSelect
                    tabs={
                      allTabs.length > 1 ? allTabs.map((tab) => tab.name) : []
                    }
                    selectedTab={selectedDataType?.name}
                    onTabChange={(value) => {
                      onDataTypeChange(
                        allTabs.find((tab) => tab.name === value)
                      );
                    }}
                  />
                );
              })()}
            </HeaderRow>
            {selectedSubSlicer ? (
              <HeaderRow>
                {(() => {
                  const visibleSubSlicers =
                    selectedSlicer.sub_slicer?.filter(
                      (sub_slicer) =>
                        debugEnabled || !(sub_slicer.is_hidden || false)
                    ) || [];
                  return (
                    <TabSelect
                      tabs={
                        visibleSubSlicers?.length > 0
                          ? visibleSubSlicers.map(
                              (sub_slicer) => sub_slicer.name
                            )
                          : []
                      }
                      selectedTab={
                        selectedSubSlicer ? selectedSubSlicer.name : null
                      }
                      onTabChange={onSubSlicerChange}
                    />
                  );
                })()}
              </HeaderRow>
            ) : null}
          </DesktopView>
          <MobileView>
            <PageTitle>
              {selectedSubSlicer
                ? selectedSubSlicer.title
                : selectedSlicer
                ? selectedSlicer.title
                : ""}
              {isCurrency
                ? isScale
                  ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Mn" : "Cr"})`
                  : ` (${is_usd ? "USD" : "INR"})`
                : isScale
                ? ` (${is_usd ? "Mn" : "Cr"})`
                : ``}
            </PageTitle>
            <HeaderRow>
              {(() => {
                let visibleSlicers = allSlicer.filter(
                  (slicer) => debugEnabled || !(slicer?.is_hidden || false)
                );
                if (visibleSlicers.length <= 1) {
                  visibleSlicers = [];
                }
                return visibleSlicers.length ? (
                  <TabSelect
                    tabs={
                      visibleSlicers.length > 0
                        ? visibleSlicers.map((slicer) => slicer.name)
                        : []
                    }
                    selectedTab={selectedSlicer ? selectedSlicer.name : null}
                    onTabChange={onSlicerChange}
                  />
                ) : null;
              })()}

              {selectedSubSlicer
                ? (() => {
                    const visibleSubSlicers =
                      selectedSlicer.sub_slicer?.filter(
                        (sub_slicer) =>
                          debugEnabled || !(sub_slicer.is_hidden || false)
                      ) || [];
                    return (
                      <TabSelect
                        tabs={
                          visibleSubSlicers?.length > 0
                            ? visibleSubSlicers.map(
                                (sub_slicer) => sub_slicer.name
                              )
                            : []
                        }
                        selectedTab={
                          selectedSubSlicer ? selectedSubSlicer.name : null
                        }
                        onTabChange={onSubSlicerChange}
                      />
                    );
                  })()
                : null}
            </HeaderRow>

            <HeaderRow>
              <TabSelect
                tabs={
                  selectedSubSlicer
                    ? selectedSubSlicer.period_type.filter((a) => a !== "FY")
                    : selectedSlicer
                    ? selectedSlicer.period_type.filter((a) => a !== "FY")
                    : []
                }
                selectedTab={selectedPeriodType}
                onTabChange={onPeriodTypeChange}
              />
              {(() => {
                const allTabs = selectedSubSlicer
                  ? selectedSubSlicer.data_type
                    ? selectedSubSlicer.data_type
                    : []
                  : selectedSlicer
                  ? selectedSlicer.data_type
                    ? selectedSlicer.data_type
                    : []
                  : [];
                return (
                  <TabSelect
                    tabs={
                      allTabs.length > 1 ? allTabs.map((tab) => tab.name) : []
                    }
                    selectedTab={selectedDataType?.name}
                    onTabChange={(value) => {
                      onDataTypeChange(
                        allTabs.find((tab) => tab.name === value)
                      );
                    }}
                  />
                );
              })()}
            </HeaderRow>
            <HeaderRow>
              {allFilter
                .filter((filter) => debugEnabled || !filter.is_hidden)
                .map((filter, id) => {
                  let nestedVisibleOptions = getFilterVisibleOptions(filter);
                  const allNestedVisibleNames =
                    listAllNestedNames(nestedVisibleOptions);

                  let filterSelectedOptions = selectedOptions[filter.type]
                    ? selectedOptions[filter.type]
                    : [];
                  filterSelectedOptions = filterSelectedOptions.filter(
                    (value) => allNestedVisibleNames.includes(value)
                  );
                  return (
                    <FilterSelect
                      key={id}
                      options={nestedVisibleOptions}
                      onSelectedChange={(selectedList) => {
                        onSelectedOptions(selectedList, filter.type);
                      }}
                      keyFieldName="name"
                      valueFieldName="name"
                      selectedOptions={filterSelectedOptions}
                      mutileSelect={true}
                      placeholder={`${filter.name} Filter`}
                      mutileSelectText={filter.name}
                      selectAllText="All"
                      selectNoneText="None"
                      borderRadius={0}
                    />
                  );
                })}
            </HeaderRow>
          </MobileView>
          <GraphWrapper>
            <Definition>
              {selectedSubSlicer
                ? selectedSubSlicer.definition
                : selectedSlicer
                ? selectedSlicer.definition
                : ""}
            </Definition>
            <CombinedGraph
              graphType={graphType}
              data={graphData}
              showlegend={legendFilter ? true : false}
              topOption={legendFilter?.top_option || []}
              allOption={legendFilter?.option?.map((opt) => opt.name) || []}
              legendOther={legendOther}
              legendFilter={legendFilter}
              scaleValue={isScale ? (is_usd ? 1000000 : 10000000) : 1}
              scaleLabel={isScale ? (is_usd ? "Mn" : "Cr") : null}
              formatCode={is_usd ? "en-US" : "hi"}
              frequency={
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType || "M"
              }
              is_percent={
                selectedDataType?.name === "100%" ||
                selectedDataType?.name === "YOY Growth"
                  ? true
                  : selectedSubSlicer
                  ? selectedSubSlicer.is_percent
                  : selectedSlicer
                  ? selectedSlicer.is_percent
                  : false
              }
              currency_unit={
                isCurrency
                  ? isScale
                    ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Mn" : "Cr"})`
                    : ` (${is_usd ? "USD" : "INR"})`
                  : isScale
                  ? ` (${is_usd ? "Mn" : "Cr"})`
                  : ``
              }
              includeOverall={includeOverall}
              dataType={selectedDataType?.name || ""}
            />
            <Inference>
              {selectedSubSlicer
                ? selectedSubSlicer.inference
                : selectedSlicer
                ? selectedSlicer.inference
                : ""}
            </Inference>
          </GraphWrapper>
          {is_admin ? (
            <RowRightData>
              <Toggle
                label={"Debug Mode"}
                isEnabled={debugEnabled}
                onChangeToggle={setDebugEnabled}
              />
            </RowRightData>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default Page;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  ${media.medium`
    padding: 10px;
  `}
  ${media.small`
    padding: 5px;
  `}
`;

const TabSelect = styled(TabOptions)`
  margin-bottom: 8px;
`;

const FilterSelect = styled(MultiSelectGraph)`
  margin-bottom: 8px;
  min-width: 100px;
  width: 200px;

  ${media.medium`
    width: 150px;
  `}
  ${media.small`
    min-width: 100px;
    width: 122px;
  `}
`;

const DesktopView = styled.div`
  ${media.small`
    display: none;
  `}
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const MobileView = styled.div`
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;  
  `}
  ${media.xlarge`
    display: none;  
  `}
`;

const PageTitle = styled.div`
  height: 30px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #020202;
  ${media.small`
    font-size: 18px;
  `}
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Definition = styled.div`
  font-size: 13px;
  line-height: 19px;
  min-height: 19px;
  padding-bottom: 5px;
`;

const Inference = styled.div`
  font-size: 13px;
  line-height: 19px;
  min-height: 19px;
  padding-left: 50px;
  ${media.small`
    padding-left: 15px;
  `}
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;

const RowRightData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
