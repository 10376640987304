import React, { useState, useEffect } from "react";
import { event } from "react-ga";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsBagCheck } from "react-icons/bs";
import { BiFridge } from "react-icons/bi";
import { MdOutlineHealthAndSafety, MdPersonalVideo } from "react-icons/md";
import MyContext from "../../utils/contexts/MyContext";
import { onSectorReportOpenCT } from "../../utils/clevertap";
import { error } from "jquery";
import { hover } from "@testing-library/user-event/dist/hover";
import { media } from "../../utils/media";

const ColumnDropDown = ({
  node_data,
  subDictionary,
  isOpen = false,
  setIsOpen = () => null,
}) => {
  const navigate = useNavigate();
  let icondic = {
    "Online Retail": <AiOutlineShoppingCart />,
    Grocery: <BsBagCheck />,
    eHealth: <MdOutlineHealthAndSafety />,
    "Digital Content": <MdPersonalVideo />,
    "Discover More": (
      <img src="/Images/square4.png" alt="" style={{ height: "11px" }} />
    ),
    Brandverse: <BiFridge />,
  };
  let handleClick = (
    val,
    key,
    filter,
    filter_value,
    subscribed,
    industry_id
  ) => {
    onSectorReportOpenCT(val, key, subscribed);
    setIsOpen(false);
    navigate(
      `/Report3/?val=${val}&key=${key}&filter_value=${filter_value}&filter=${filter}&industry_id=${industry_id}`
    );
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsOpen]);

  return (
    <TreeGridDiv isOpen={isOpen}>
      {node_data
        .filter((val) => val.report_name === "Online Retail")
        .map((val, index) => (
          <SectorGridOnline key={index}>
            <SectorGridTitle>
              {icondic[val.report_name]}
              {val.report_name}
            </SectorGridTitle>
            <div style={{ color: "black" }}>
              {val.children.map((ele, i) => {
                const url = `/Report3/?val=${encodeURIComponent(
                  ele.report_name
                )}&key=${encodeURIComponent(
                  ele.id
                )}&filter_value=${encodeURIComponent(
                  ele.filter_value
                )}&filter=${encodeURIComponent(
                  ele.filter
                )}&industry_id=${encodeURIComponent(ele.industry_id)}`;
                return (
                  <ReportLink
                    key={i}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(
                        encodeURIComponent(ele.report_name),
                        ele.id,
                        encodeURIComponent(ele.filter),
                        encodeURIComponent(ele.filter_value),
                        ele.subscribed,
                        ele.industry_id
                      );
                    }}
                  >
                    {ele.report_name}
                    {ele.id in subDictionary ? (
                      <SubscriptionBox>Subscribed</SubscriptionBox>
                    ) : null}
                  </ReportLink>
                );
              })}
            </div>
          </SectorGridOnline>
        ))}
      {node_data
        .filter(
          (val) =>
            val.report_name !== "Online Retail" &&
            val.report_name !== "Discover More"
        )
        .map((val, index) => (
          <SectorGrid key={index}>
            <SectorGridTitle>
              {icondic[val.report_name]}
              {val.report_name}
            </SectorGridTitle>
            <div>
              {val.children.map((ele, i) => {
                const url = `/Report3/?val=${encodeURIComponent(
                  ele.report_name
                )}&key=${encodeURIComponent(
                  ele.id
                )}&filter_value=${encodeURIComponent(
                  ele.filter_value
                )}&filter=${encodeURIComponent(
                  ele.filter
                )}&industry_id=${encodeURIComponent(ele.industry_id)}`;
                return (
                  <ReportLink
                    key={i}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(
                        encodeURIComponent(ele.report_name),
                        ele.id,
                        encodeURIComponent(ele.filter),
                        encodeURIComponent(ele.filter_value),
                        ele.subscribed,
                        ele.industry_id
                      );
                    }}
                  >
                    {ele.report_name}{" "}
                    {ele.id in subDictionary ? (
                      <SubscriptionBox>Subscribed</SubscriptionBox>
                    ) : null}
                  </ReportLink>
                );
              })}
            </div>
          </SectorGrid>
        ))}
      <SectorGridDiscover>
        <SectorGridTitle>
          {icondic["Discover More"]}Discover More
        </SectorGridTitle>
        {node_data
          .filter((val) => val.report_name === "Discover More")
          .map((val, index) => (
            <div key={index}>
              {val.children.map((ele, i) => {
                const url = `/Report3/?val=${encodeURIComponent(
                  ele.report_name
                )}&key=${encodeURIComponent(
                  ele.id
                )}&filter_value=${encodeURIComponent(
                  ele.filter_value
                )}&filter=${encodeURIComponent(
                  ele.filter
                )}&industry_id=${encodeURIComponent(ele.industry_id)}`;
                return (
                  <ReportLink
                    key={i}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(
                        encodeURIComponent(ele.report_name),
                        ele.id,
                        encodeURIComponent(ele.filter),
                        encodeURIComponent(ele.filter_value),
                        ele.subscribed,
                        ele.industry_id
                      );
                    }}
                  >
                    {ele.report_name}{" "}
                    {ele.id in subDictionary ? (
                      <SubscriptionBox>Subscribed</SubscriptionBox>
                    ) : null}
                  </ReportLink>
                );
              })}
            </div>
          ))}
      </SectorGridDiscover>
    </TreeGridDiv>
  );
};

export default ColumnDropDown;

const TreeGridDiv = styled.div`
  display: ${(props) => (props.isOpen ? "grid" : "none")};
  background-color: #ffffff;
  width: 100%;
  max-width: 100%;
  max-height: 90vh;
  padding: 16px 3.5vw 0px;
  box-shadow: 2px 2px 4px 0px #00000040;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  grid-template-areas:
    "Online . . Discoverdiv"
    "Online . . Discoverdiv";

  ${media.small`
		padding: 5px calc(3.5vw - 2.5px) 0px;
		
    grid-auto-rows: 1fr;
  	grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"Online ."
			"Online ."
			". Discoverdiv";
  `}
`;

const SectorGrid = styled.div`
  max-width: 100%;
  height: auto;
  padding: 20px;
  ${media.small`
		padding: 8px 2.5px;
  `}
`;

const SectorGridOnline = styled.div`
  max-width: 100%;
  height: auto;
  padding: 20px;
  grid-area: Online;
  ${media.small`
		padding: 8px 2.5px;
  `}
`;
const SectorGridDiscover = styled.div`
  max-width: 100%;
  height: auto;
  padding: 20px;
  grid-area: Discoverdiv;
  ${media.small`
		padding: 8px 2.5px;
  `}
`;

const SectorGridTitle = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  column-gap: 5px;
  row-gap: 5px;
  font-size: 16px;
  font-weight: 500;
  ${media.small`
		font-size: 13px;
		column-gap: 2.5px;
		row-gap: 2.5px;
  `}
`;

const ReportLink = styled.a`
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  font-size: 14px;
  padding-left: 10px;
  text-decoration: none !important;
  &:hover {
    background-color: #0099fe;
    color: #ffffff;
    cursor: pointer;
  }
  ${media.small`
		font-size: 10px;
		padding: 2.5px;
  `}
`;

const SubscriptionBox = styled.span`
  margin-left: 5px;
  background-color: #0a99fe;
  font-size: 10px;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 5px;
  ${media.small`
		font-size: 8px;
		padding: 1px 3px;
  `}
`;
